export { default as salad1 } from './salad_1.png';
export { default as salad2 } from './salad_2.png';
export { default as salad3 } from './salad_3.png';
export { default as environmentFriendly1 } from './environment_friendly_1.png';
export { default as environmentFriendly2 } from './environment_friendly_2.png';
export { default as environmentFriendly3 } from './environment_friendly_3.png';
export { default as convenienceProduct1 } from './convenience_product_1.png';
export { default as convenienceProduct2 } from './convenience_product_2.png';
export { default as convenienceProduct3 } from './convenience_product_3.png';
export { default as mainBanner } from './main_banner.png';
export { default as mainBannerEN } from './main_banner_en.png';
export { default as mainBannerMobile } from './main_banner_mobile.png';
export { default as mainBannerMobileEN } from './main_banner_mobile_en.png';
export { default as saladBanner } from './salad_banner.png';
export { default as saladBannerEN } from './salad_banner_en.png';
export { default as saladBannerMobile } from './salad_banner_mobile.png';
export { default as saladBannerMobileEN } from './salad_banner_mobile_en.png';
export { default as environmentFriendlyBanner } from './environment_friendly_banner.png';
export { default as environmentFriendlyBannerEN } from './environment_friendly_banner_en.png';
export { default as environmentFriendlyBannerMobile } from './environment_friendly_banner_mobile.png';
export { default as environmentFriendlyBannerMobileEN } from './environment_friendly_banner_mobile_en.png';
export { default as convenienceBanner } from './convenience_banner.png';
export { default as convenienceBannerEN } from './convinience_banner_en.png';
export { default as convenienceBannerMobile } from './convenience_banner_mobile.png';
export { default as convenienceBannerMobileEN } from './convenience_banner_mobile_en.png';
export { default as subBanner } from './sub_banner.png';
export { default as subBannerEN } from './sub_banner_en.png';
export { default as messageIcon } from './message_icon.png';
export { default as subBannerMobile } from './sub_banner_mobile.png';
export { default as subBannerMobileEN } from './sub_banner_mobile_en.png';
