import React, { FC, Fragment, useRef } from 'react';
import { Spin } from 'antd';
import Helmet from 'react-helmet';
import { RouteComponentProps } from 'react-router-dom';
import media from 'utils/mediaStyle';
import injectReducer from 'utils/injectReducer';
import reducer from './reducer';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { ApplicationRootState } from 'types';
import { ContainerState } from './types';
import selectKamereoBrandPage from './selectors';
import KamereoBrandListSection from './components/KamereoBrandListSection';
import BrandProductList from './components/BrandProductList';
import injectSaga from 'utils/injectSaga';
import renderWithWebDomain from 'utils/renderWithWebDomain';
import saga from './saga';
import styled from 'styles/styled-components';
import { subBanner, subBannerEN, subBannerMobile, subBannerMobileEN } from './images';
import KamereoMessage from './components/KamereoMessage';
import KamereoExplore from './components/KamereoExplore';
import { Viewport } from 'components/ViewportProvider';

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 75pt);
  margin-top: 99.66px;
`;

const SubBanner = styled.div`
  margin-top: 20px;
  padding: 0 16px;
  img {
    width: 100%;
  }

  ${media.md`
   margin-top: 46px;
    padding: 0 ;

`};
`;

export interface IStateProps {
  kamereoPage: ContainerState;
}

type Props = IStateProps & RouteComponentProps;
const KamereoBrandPage: FC<Props> = (props) => {
  const {
    kamereoPage: { brands, sections, loading },
  } = props;

  const lang = window.localStorage.getItem('lang') || 'vi';

  const saladRef = useRef<HTMLDivElement>(null);
  const environmentRef = useRef<HTMLDivElement>(null);
  const convinienceRef = useRef<HTMLDivElement>(null);

  return (
    <Fragment>
      <Helmet>
        <title>Dòng sản phẩm thương hiệu Kamereo</title>
        <meta property="og:url" content={(renderWithWebDomain(process.env.APP_ENV), 'thuong-hieu-kamereo')} />
        <link rel="canonical" href={(renderWithWebDomain(process.env.APP_ENV), 'thuong-hieu-kamereo')} />
        <link rel="alternate" href={(renderWithWebDomain(process.env.APP_ENV), 'thuong-hieu-kamereo')} />
        <meta
          name="description"
          content="KAMEREO hướng tới mô hình kinh doanh bền vững, thân thiện với môi trường. Các sản phẩm mới có khả năng tự phân hủy sinh học lên tới 60% trong vòng 2 năm, góp phần giảm thiểu rác thải nhựa gây ô nhiễm nghiêm trọng, điều mà hiện nay đang là 1 vấn nạn rất nhức nhối của xã hội. "
        />
        <meta property="og:title" content="Dòng sản phẩm thương hiệu Kamereo" />
        <meta property="og:type" content="website" />
      </Helmet>
      <Spin spinning={loading}>
        <MainContainer>
          <KamereoBrandListSection listRef={[saladRef, environmentRef, convinienceRef]} brandData={brands} />

          {/* Salad */}
          <div ref={saladRef}>
            <BrandProductList sectionData={sections['KAMEREO_VEGGIES']} sectionName="KAMEREO_VEGGIES" />
          </div>

          {/* Sub Banner */}
          <Viewport.Consumer>
            {({ width }) => (
              <SubBanner>
                {' '}
                <img
                  src={
                    width > 1024
                      ? lang === 'vi'
                        ? subBanner
                        : subBannerEN
                      : lang === 'vi'
                        ? subBannerMobile
                        : subBannerMobileEN
                  }
                  loading="lazy"
                />
              </SubBanner>
            )}
          </Viewport.Consumer>

          {/* Enviroment Friendly */}
          <div ref={environmentRef}>
            <BrandProductList sectionData={sections['KAMEREO_ECO']} sectionName="KAMEREO_ECO" />
          </div>

          {/* Kamereo Message */}
          <KamereoMessage />

          {/* Convinience product */}
          <div ref={convinienceRef}>
            <BrandProductList sectionData={sections['KAMEREO_DAILY']} sectionName="KAMEREO_DAILY" />
          </div>

          {/* Kamereo Explore */}
          <KamereoExplore kamereoProducts={sections} />
        </MainContainer>
      </Spin>
    </Fragment>
  );
};

const mapStateToProps = createStructuredSelector<ApplicationRootState, IStateProps>({
  kamereoPage: selectKamereoBrandPage(),
});

const withConnect = connect(mapStateToProps);

const withReducer = injectReducer({
  key: 'kamereoBrandPage',
  reducer: reducer,
});

const withSaga = injectSaga({ key: 'kamereoBrandPage', saga: saga });

export default compose(withConnect, withReducer, withSaga)(KamereoBrandPage);
